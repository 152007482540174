@import 'node_modules/@little-phil/frontend/scss/variables';

:host {
    display: block;
}

.track {
    background-color: $reliable-blue-10;
    height: 8px !important;
}

.progress-bar__wrapper {
    height: 100%;
}

.progress-bar {
    height: 100%;

    &.animated {
        animation: progressBar 1s ease-in-out;
        animation-fill-mode: both;
    }
}

@keyframes progressBar {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
