:host {
    display: block;
}

button {
    white-space: nowrap;
}

.btn {
    min-width: auto; // clear inherited button styling
}
