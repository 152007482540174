@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'node_modules/@little-phil/frontend/scss/partials/mixins/position';

.loader__wrapper {
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-fullscreen-loader;

    > .loader__spinner {
        @include absolute-center;
    }
}
