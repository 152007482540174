@import 'node_modules/@little-phil/frontend/scss/variables';

:host {
    display: block;
}

.cause-list-item-wrapper {
    outline: none;
}

.cause-details-emoji {
    display: block;
    width: 16px;
    min-width: 16px;
    font-size: $font-size-xs;
    line-height: $line-height-xs * 1rem;
    text-align: center;
}

.category-dot {
    $size: 16px;
    $h-margin: calc((16px - $size) / 2);
    display: block;
    border-radius: $size;
    min-height: $size;
    max-height: $size;
    min-width: $size;
    max-width: $size;
    margin: 0 $h-margin;
}

.cause-details-text {
    font-size: $font-size-xs;
    line-height: $line-height-xs * 1rem;
    color: $reliable-blue;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.charity-link:hover {
    color: $reliable-blue;
    cursor: pointer;
}

.charity-logo {
    object-fit: cover;
}

.display-small .charity-logo,
.display-card .charity-logo {
    width: 75px;
    height: 75px;
}

.display-large .charity-logo {
    width: 115px;
    height: 115px;
}

@media (hover: hover) {
    .expand-on-hover {
        transition: 0.25s;

        &:hover {
            transform: translateY(-5px);
        }
    }
}

.cause-name {
    text-overflow: ellipsis;
    overflow: hidden;
}

.cause-details {
    min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
}

.cause-details-line {
    min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
}
