@import 'node_modules/@little-phil/frontend/scss/variables';

:host {
    display: block;
}

.stat-name {
    display: block;
    font-size: $font-size-xs;
    line-height: $line-height-xs * 1rem;
    font-family: $font-stack;
}

.separator {
    background-color: $reliable-blue-10;
    width: 1px;
}
