@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'src/assets/scss/partials/mixins/screen-size';

.footer {
    display: flex;
    height: 100px;

    @include screen-size-sm {
        height: initial;
        padding: 50px 0 30px;
    }

    i {
        color: #84d2e2;
        font-size: 30px;
    }

    a {
        text-decoration: none;

        &:not(:last-of-type) {
            margin-right: 15px;
        }
    }

    #scroll-to-top {
        * {
            cursor: pointer;
        }

        span {
            margin-left: 10px;
        }

        @include screen-size-sm {
            margin: 30px 0 60px;
        }
    }

    * {
        @include screen-size-sm {
            text-align: center !important;
            margin: 0 auto;

            &:not(:last-of-type) {
                margin-bottom: 15px;

                @include screen-size-sm {
                    margin-bottom: 20px;
                }
            }
        }
    }

}
