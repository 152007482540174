@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-left-center {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%)
}
