@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

:host {
    background-color: white;
    display: block;
}

.nav-item.dropdown {
    z-index: 1030;
}

.navbar-toggler {
    box-shadow: none;
    outline: none;
}

.navbar-brand {
    align-items: center;
    display: flex;
    padding: 0;
    height: $nav-bar-image-height;

    img {
        height: 100%;
    }

    @include media-breakpoint-down('md') {
        img {
            height: $nav-bar-image-height-mobile;
        }
    }
}

// Allow dropdowns to expand on all screen sizes
.navbar .nav-item:hover .dropdown-menu {
    display: block;
    right: 0;
}

.navbar .nav-link {
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;

    &.active {
        border-bottom-color: $reliable-blue;
    }
}

.mobile-menu {
    position: fixed;
    left: 100%;
    top: 0;
    bottom: 0;
    z-index: $z-index-nav-bar;

    &.expanded {
        .mobile-menu-backdrop {
            left: 0;
            opacity: 0.2;
        }

        .mobile-menu-items {
            left: -300px;
        }
    }

    &.collapsing-menu {
        .mobile-menu-backdrop {
            left: -100%;
            opacity: 0;
        }

        .mobile-menu-items {
            left: 0;
        }
    }
}

.mobile-menu-backdrop {
    background-color: black;
    opacity: 0;
    cursor: pointer;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    right: 0;
    transition: opacity 300ms;
    z-index: -1;
}

.mobile-menu-items {
    background-color: white;
    position: absolute;
    height: 100%;
    left: 0;
    overflow-y: scroll;
    width: 300px;
    transition: left 300ms;
}
