@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'src/assets/scss/partials/mixins/screen-size';

:host {
    display: block;
}

.pagination-btn {
    width: 160px;
    height: 60px;
    outline: none;
}
