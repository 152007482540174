@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'src/assets/scss/partials/mixins/screen-size';

.connect-section {
    background-color: $reliable-blue-10;
    display: flex;
    height: 120px;

    @include screen-size-sm {
        height: 60px
    }

    .social-icon {
        font-size: 20px;
    }
}
