@import 'node_modules/@little-phil/frontend/scss/variables';

:host {
    display: block;
}

.progress-bar-wrapper {
    background-color: $reliable-blue-5;
    height: 8px !important;
}

.progress-bar {
    height: 100%;
}
