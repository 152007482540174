@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

:host {
    display: block;
}

a {
    text-decoration: none;
}

.item:hover:not(.active) {
    background-color: $reliable-blue-10;
    color: $reliable-blue;
}

.active {
    background-color: $reliable-blue-75;
    color: white;
}
