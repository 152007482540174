@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'node_modules/@little-phil/frontend/scss/partials/functions/rem-to-px';
@import 'node_modules/@little-phil/frontend/scss/partials/typography';
@import 'src/assets/scss/partials/mixins/screen-size';

$search-transition-duration: 1s;

.filter-btn {
    align-items: center;
    border: 1px solid $reliable-blue-25;
    cursor: pointer;
    display: flex;
    height: 50px;
}

.filter-btn-mobile {
    bottom: 0;
    padding-bottom: 1rem;
    position: sticky;
}

.filter__wrapper {
    position: relative;
    display: flex;
    background-color: $reliable-blue-5;
    align-items: center;
    justify-content: flex-start;
}

.filter-overlay__wrapper {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: flex;
    overflow-x: scroll;
    z-index: $z-index-explore-filter-overlay;

    @include screen-size-sm {
        background-color: white;
    }
}

.filter-overlay__container {
    padding: 0 1rem;
    margin: auto; // fixes flex overflow issue with align-items and justify-content https://stackoverflow.com/a/33455342/6284106
    max-width: 800px;
    width: 100%;

    @include screen-size-sm {
        height: 100%;
        padding: 0;
    }
}

.filter-overlay {
    background-color: white;
    cursor: default;
    width: 100%;

    @include screen-size-sm-up {
        border-radius: $border-radius-lg;
    }

    @include screen-size-sm {
        min-height: 100%;
    }
}

.search__wrapper {
    background: white;
    border: 1px solid $reliable-blue-25;
    height: 50px;
    overflow: hidden;
}

.search-input {
    background-color: transparent;
    border: none;
    outline: none;

    &::-webkit-input-placeholder {
        color: $reliable-blue-25;
    }

    &::-moz-placeholder {
        color: $reliable-blue-25;
    }

    &:-ms-input-placeholder {
        color: $reliable-blue-25;
    }

    &::placeholder {
        color: $reliable-blue-25;
    }
}

.tabs__wrapper {
    ::ng-deep .ng-select > .ng-select-container {
        background-color: transparent;
        border: none;
        box-shadow: none !important;
        height: inherit;
    }

    ::ng-deep .ng-select > .ng-select-container .ng-value-container {
        padding-left: 0;
        padding-right: 1rem;
    }

    ::ng-deep .ng-select > .ng-select-container > .ng-value-container .ng-value {
        @extend h1;
    }

    ::ng-deep .ng-dropdown-panel,
    ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items {
        border-color: $reliable-blue-25 !important;
        border-radius: $border-radius !important;
    }
}

.sort__wrapper {
    position: relative;

    ::ng-deep .ng-select > .ng-select-container {
        box-shadow: none !important;
        border-color: $reliable-blue-25 !important;
        border-radius: $border-radius;
        height: 50px;
    }

    ::ng-deep .ng-select > .ng-select-container .ng-value-container {
        padding-left: calc(2rem + 16px); // icon is 16px wide
    }

    ::ng-deep .ng-select .ng-arrow-wrapper {
        margin-right: 1rem;
    }

    ::ng-deep .ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    ::ng-deep .ng-placeholder {
        color: $input-placeholder-color !important;
    }

    ::ng-deep .ng-dropdown-panel.ng-select-bottom,
    ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items {
        border-bottom-left-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
    }
}

.sort-icon__wrapper {
    position: absolute;
    top: 0;
    left: 1px; // border width
    bottom: 0;
    z-index: 1010;
}

// Wrap ::ng-deep styles in host so that they don't leak into other components
:host {
    ::ng-deep .ng-select > .ng-select-container {
        cursor: pointer !important;
    }

    ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
    ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
        background-color: $reliable-blue-5;
    }

    ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        padding: 1rem;
    }

    ::ng-deep .ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
        border-color: $reliable-blue !important;
        border-style: solid !important;
        border-width: 0 1px 1px 0 !important;
        transform: rotate(45deg);
        top: -2px;
        -webkit-transform: rotate(45deg);
        height: 10px !important;
        width: 10px !important;
    }

    ::ng-deep .ng-select.ng-select-opened .ng-select-container .ng-arrow-wrapper .ng-arrow {
        border-width: 1px 0 0 1px !important;
        top: 5px;
    }

    ::ng-deep .ng-select .ng-arrow-wrapper {
        padding-right: 0;
        width: 15px;
    }
}

.search__wrapper,
.sort__wrapper {
    width: 300px;
}

@include screen-size-lg {
    .search__wrapper,
    .sort__wrapper {
        width: 250px;
    }
}

@include screen-size-md {
    .right-items,
    .search__wrapper,
    .sort__wrapper {
        width: 100%;
    }
}
